<template>
  <div class="kt-footer kt-grid__item  mt-auto" id="kt_footer">
    <div class="kt-footer__wrapper w-100 d-flex justify-content-between px-4 py-2">
      <a target="_blank" href="https://www.facebook.com/sweetecom/" class="d-none d-sm-block btn btn-facebook btn-sm"><i
          class="fab fa-facebook-f"></i> Join us on Facebook</a>

      <div class="kt-footer__menu">
        <a href="https://apps.shopify.com/partners/sweet-ecom" target='_blank' class="kt-footer__menu-link kt-link my-auto">Our
          apps</a>
        <router-link data-v-step="faq" :to="{ name: 'faq'}" target='_blank'
                     class="kt-footer__menu-link kt-link my-auto">FAQ
        </router-link>
        <router-link :to="{ name: 'policy'}" target='_blank' class="kt-footer__menu-link kt-link my-auto">Privacy policy
        </router-link>
        <div class="kt-footer__copyright my-auto ml-3 p-0">SweetEcom © {{currentYear}}</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "footer-component",
  computed: {
    currentYear(){
      return (new Date).getFullYear();
    }
  }
}
</script>

<style scoped>

</style>